exports.components = {
  "component---src-pitayan-gatsby-theme-pitayan-pages-404-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/pages/404/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-pages-404-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-pages-authors-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/pages/authors/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-pages-authors-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-pages-categories-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/pages/categories/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-pages-categories-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-pages-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/pages/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-pages-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-all-posts-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/allPosts/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-all-posts-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-author-posts-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/authorPosts/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-author-posts-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-category-posts-index-tsx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/categoryPosts/index.tsx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-category-posts-index-tsx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-becoming-a-subscriber-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/becoming-a-subscriber/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-becoming-a-subscriber-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-confirmed-appointments-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/confirmed-appointments/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-confirmed-appointments-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-deleting-clients-archiving-clients-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/deleting-clients-archiving-clients/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-deleting-clients-archiving-clients-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-hide-show-calendars-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/hide-show-calendars/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-hide-show-calendars-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-access-filled-out-intake-forms-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/how-to-access-filled-out-intake-forms/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-access-filled-out-intake-forms-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-create-and-redeem-packages-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/how-to-create-and-redeem-packages/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-create-and-redeem-packages-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-edit-intake-forms-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/how-to-edit-intake-forms/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-edit-intake-forms-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-link-your-online-scheduler-to-your-website-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/how-to-link-your-online-scheduler-to-your-website/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-link-your-online-scheduler-to-your-website-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-send-client-an-intake-form-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/how-to-send-client-an-intake-form/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-how-to-send-client-an-intake-form-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-opting-back-in-for-sms-reminders-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/opting-back-in-for-sms-reminders/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-opting-back-in-for-sms-reminders-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-what-does-complaint-mean-in-email-logs-index-mdx": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/post/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/posts/what-does-complaint-mean-in-email-logs/index.mdx" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-post-index-tsx-content-file-path-content-posts-what-does-complaint-mean-in-email-logs-index-mdx" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-site-index-tsx-content-file-path-content-site-about-index-md": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/site/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/site/about/index.md" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-site-index-tsx-content-file-path-content-site-about-index-md" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-site-index-tsx-content-file-path-content-site-privacy-policy-index-md": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/site/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/site/privacy-policy/index.md" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-site-index-tsx-content-file-path-content-site-privacy-policy-index-md" */),
  "component---src-pitayan-gatsby-theme-pitayan-templates-site-index-tsx-content-file-path-content-site-terms-and-conditions-index-md": () => import("./../../../src/@pitayan/gatsby-theme-pitayan/templates/site/index.tsx?__contentFilePath=/home/runner/work/bodyworkbuddy-support/bodyworkbuddy-support/content/site/terms-and-conditions/index.md" /* webpackChunkName: "component---src-pitayan-gatsby-theme-pitayan-templates-site-index-tsx-content-file-path-content-site-terms-and-conditions-index-md" */)
}

